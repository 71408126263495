import React from "react"
import Template from "../../layouts/base"
import { Col, Row } from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import queryString from "query-string"

import rt from "../../assets/images/ingests/real_time.svg"
import btch from "../../assets/images/ingests/batch.svg"
import mtdt from "../../assets/images/ingests/carga_metadatos.svg"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import withAuth from "../../components/withAuthHoc"
import LocalizedAniLink from "../../components/localizedAniLink"

class IngestList extends React.Component {
  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    let linked_with

    if (this.props.location && this.props.location.search) {
      const queryParams = queryString.parse(this.props.location.search)

      if (queryParams) {
        linked_with = queryParams.linked_with
      }
    }

    this.state = {
      linked_with,
    }

    this._getIngestTypeLinks = this._getIngestTypeLinks.bind(this)
    this._composeLinkTo = this._composeLinkTo.bind(this)
  }

  _getIngestTypeLinks() {
    const links = [
      {
        order: 1,
        label: "new-ingestion-rt-title",
        description: "new-ingestion-rt-description",
        path: rt,
        linkTo: "/ingest/new?t=REAL_TIME",
      },
      // {
      //   order: 2,
      //   label: "new-ingestion-nrt-title",
      //   description: "new-ingestion-nrt-description",
      //   path: nrt,
      //   linkTo: "/ingest/new?t=NEAR_REAL_TIME",
      // },
      {
        order: 2,
        label: "new-ingestion-batch-title",
        description: "new-ingestion-batch-description",
        path: btch,
        linkTo: "/ingest/new?t=BATCH",
      },
    ]

    if (!this.state.linked_with) {
      links.push({
        order: 3,
        label: "new-ingestion-metadata-title",
        description: "new-ingestion-metadata-description",
        path: mtdt,
        linkTo: "/ingest/new?t=METADATA",
      })
    }

    return links
  }

  _composeLinkTo(linkTo) {
    return this.state.linked_with
      ? `${linkTo}&id=${this.state.linked_with}&linked_with=${this.state.linked_with}`
      : linkTo
  }

  render() {
    return (
      <Template selected={["ingest", "ingest-new"]}>
        <CustomBreadcrumb
          crumbs={[
            {
              label: this._formatMessage("menu-ingestions-management"),
              link: "/ingest",
            },
            this._formatMessage("new-ingestion-breadcrumb"),
          ]}
        />

        <Row>
          <Col className="intro">
            <h1>{this._formatMessage("new-ingestion-options-title")}</h1>
            <p>{this._formatMessage("new-ingestion-options-subheader")}</p>
          </Col>
        </Row>

        <Row type="flex" align="middle">
          {this._getIngestTypeLinks().map(item => (
            <Col className="cromo hvr-grow-shadow" span={7} key={item.order}>
              <LocalizedAniLink fade to={this._composeLinkTo(item.linkTo)}>
                <h2>{this._formatMessage(item.label)}</h2>
                <p>{this._formatMessage(item.description)}</p>
                <img
                  data-testid="logo"
                  alt="Cloud Process History"
                  src={item.path}
                />
              </LocalizedAniLink>
            </Col>
          ))}
        </Row>
      </Template>
    )
  }
}

export default injectIntl(withAuth(IngestList))
